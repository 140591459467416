.detail-4,
.detail-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  /*min-height: 66px;*/
  /*min-width: 696px;*/
  width: 100%;
}

.languages,
.languages-1 {
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  white-space: nowrap;
}

.label-36,
.label-37 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 8px;
  min-height: 34px;
  position: relative;
}

