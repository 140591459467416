.card-experience-2 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--neutralbackground-white);
  border-radius: 8px;
  box-shadow: 0px 12px 32px #2b353e14;
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 142px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
}

.title-15 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 8px;
  min-height: 73px;
}

.title-16 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 4px;
}

.web-mobile-developer-1 {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 23.4px;
  margin-top: -1px;
  white-space: nowrap;
  width: 90%;
}

.the-maul-1 {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 20.8px;
  white-space: nowrap;
  width: 90%;
}

.label-39 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 8px;
  min-height: 21px;
}

.startup-studio-2,
.nov-2021-6-months-2 {
  letter-spacing: 0;
  line-height: 21px;
  white-space: nowrap;
}

.two-projects-maint-1 {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 21px;
  width: 90%;
}


.card-experience-2.card-experience-3 .two-projects-maint-1 {
  margin-top: 1.5rem;
  /*white-space: unset;*/
}

.card-experience-2.card-experience-4 .two-projects-maint-1 {
  margin-top: 1rem;
}

