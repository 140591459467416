.contact-17 {
  align-items: flex-start;
  background-color: var(--link-water);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 199px;
  overflow: hidden;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  position: relative;
  width: 595px;
}

.send-e-mail-or-call-me-3 {
  align-self: stretch;
  height: 31px;
  letter-spacing: 0;
  line-height: 31.2px;
  margin-top: -1px;
  white-space: nowrap;
  width: 547px;
}

