.icon-3 {
  align-items: flex-start;
  border-radius: 100px;
  display: flex;
  gap: 8px;
  min-height: 36px;
  min-width: 36px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

.iconnavigationarrow_forward_24px-8 {
  height: 16px;
  width: 16px;
}

