.detail-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 683px;
}

.lorem-ipsum-color-dot-sit-amet-1 {
  align-self: stretch;
  height: 31px;
  letter-spacing: 0;
  line-height: 31.2px;
  margin-top: -1px;
  white-space: nowrap;
  width: 804px;
}

.text-1 {
  align-self: stretch;
  height: 120px;
  letter-spacing: 0;
  line-height: 24px;
  width: 804px;
}

.image {
  height: 500px;
  object-fit: cover;
  width: 808px;
}

