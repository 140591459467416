.button-primary-large {
  align-items: center;
  background-color: var(--primaryblue);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 53px;
  min-width: 141px;
  padding: 16px 24px;
  transition: all 0.2s ease-in-out;
}

.button-primary-large:hover {
  transform: scale(1.05);
}

.primary {
  letter-spacing: 0.72px;
  line-height: 20.7px;
  margin-top: -1px;
  white-space: nowrap;
}

.button-primary-large.button-primary-large-1,
.button-primary-large.button-primary-large-2 {
  cursor: unset;
  flex: 1;
  min-width: unset;
  transition: all 0.2s ease-in-out;
}

.button-primary-large.button-primary-large-3 {
  align-self: stretch;
  cursor: unset;
  min-width: unset;
  transition: all 0.2s ease-in-out;
}

