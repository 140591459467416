.experiences {
  align-items: flex-start;
  /*align-self: stretch;*/
  display: flex;
  flex-direction: column;
  gap: 24px;
  /*min-height: 1145px;*/
  position: relative;
  width: 55%;
}

.experiences-1 {
  /*align-self: stretch;*/
  height: 42px;
  letter-spacing: 0;
  line-height: 41.6px;
  margin-top: -1px;
  white-space: nowrap;
  width: 90%;
}

@media (max-width: 1240px) {
  .experiences {
    width: 90%;
  }
}
