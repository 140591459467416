.contact-11,
.contact-12 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 4px;
  min-height: 43px;
}

.email-1,
.email-2 {
  align-self: stretch;
  height: 18px;
  letter-spacing: 0.48px;
  line-height: 18px;
  margin-top: -1px;
  white-space: nowrap;
  width: 196px;
}

.hotakfaisalgmailcom-1,
.hotakfaisalgmailcom-2 {
  align-self: stretch;
  height: 21px;
  letter-spacing: 0;
  line-height: 20.8px;
  white-space: nowrap;
  width: 196px;
}

