.contact-13 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top: 9.8rem;
  z-index: 2;
  width: 100%;
}

.header-6 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;
  padding: 2rem;
}

.title-19 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.place-2 {
  align-self: stretch;
  /*height: 42px;*/
  letter-spacing: 0;
  line-height: 41.6px;
  /*margin-top: -1px;*/
  /*white-space: nowrap;*/
  /*width: 1222px;*/
}

.interested-in-working-with-me-2 {
  align-self: stretch;
  /*height: 58px;*/
  letter-spacing: 0;
  line-height: 57.6px;
  /*white-space: nowrap;*/
  /*width: 1222px;*/
}

.lets-start-collabor-1 {
  align-self: stretch;
  /*height: 27px;*/
  letter-spacing: 0;
  line-height: 27px;
  /*white-space: nowrap;*/
  /*width: 1222px;*/
}

.contact-14 {
  /*align-items: flex-start;*/
  flex-wrap: wrap;
  display: flex;
  gap: 32px;
  position: relative;
  padding: 2rem;
  width: 100%;
  justify-content: center;
}

@media (max-width: 1280px) {
  .contact-14 {
    justify-content: center;

  }
}