.top-nav-1 {
  background-color: var(--link-water);
  display: flex;
  gap: 10%;
  left: 0;
  padding: 1.5rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 6;
}

.logo-1 {
  cursor: pointer;
  height: 40px;
  transition: all 0.2s ease-in-out;
  width: 164px;
}

.logo-1:hover,
.menu-item:hover {
  transform: scale(1.1);
}

.menu {
  align-items: center;
  display: flex;
  flex: 1;
  gap: 32px;
  justify-content: flex-end;
  position: absolute;
  right: 5%;
  margin-top: 0.6rem;
}

.toggled-menu {
  align-items: center;
  display: flex;
  visibility: hidden;
  opacity: 0;
  flex: 1;
  gap: 32px;
  justify-content: flex-end;
  height: 15rem;
  transition: visibility 0s, opacity 0s linear;
  position: absolute;
  flex-direction: column;
  margin-top: 4rem;
  background-color: var(--link-water);
  left: 0;
  width: 100%;
  padding: 2rem;
}

.menu-item {
  cursor: pointer;
  letter-spacing: 0;
  line-height: 23.4px;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
}

.burger-icon {
  display: none;
}

@media (max-width: 620px) {
  .burger-icon {
    display: block;
    background-image: url(/public/img/icon-hamburger.svg);
    background-size: 100% 100%;
    height: 2rem;
    width: 2rem;
    position: absolute;
    right: 5%;
  }
  .menu {
    display: none;
  }
}
