.tab-3,
.tab-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-height: 38px;
  min-width: 89px;
}

.label-42,
.label-43 {
  letter-spacing: 0;
  line-height: 23.4px;
  margin-top: -1px;
  white-space: nowrap;
}

.line-4,
.line-5 {
  align-self: stretch;
  height: 1px;
  margin-bottom: -0.75px;
  object-fit: contain;
}

