.projects-4,
.projects-5 {
  align-items: flex-start;
  display: flex;
  gap: 32px;
  width: 100%;
}

@media (max-width: 1240px) {
  .projects-4,
  .projects-5 {
    flex-direction: column;
  }
}

