.contact-7,
.contact-9 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 196px;
  min-width: 256px;
}

.contact-8,
.contact-10 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 16px;
  /*min-height: 104px;*/
  min-height: 52px;
  position: relative;
}

.line,
.line-1 {
  align-self: stretch;
  height: 1px;
  object-fit: contain;
}

.social,
.social-1 {
  align-items: flex-start;
  display: flex;
  gap: 16px;
  min-height: 44px;
  min-width: 104px;
  position: relative;
}

