.contact-15 {
  align-items: flex-start;
  background-color: var(--link-water);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
  padding: 1.5rem;
  position: relative;
}

.send-e-mail-or-call-me-2 {
  align-self: stretch;
  /*height: 31px;*/
  letter-spacing: 0;
  line-height: 31.2px;
}

