.card-experience {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--neutralbackground-white);
  border-radius: 8px;
  box-shadow: 0 12px 32px #2b353e14;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
}

.title-13 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 8px;
}

.title-14 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 4px;
}

.web-mobile-developer {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 23.4px;
  margin-top: -1px;
  width: 90%;
}

.the-maul {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 1.5rem;
  width: 90%;
}

.label-38 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 8px;
  min-height: 21px;
}

.startup-studio,
.intern,
.nov-2021-6-months {
  letter-spacing: 0;
  line-height: 21px;
}

.dot {
  background-color: var(--neutralblack-60);
  border-radius: 3px;
  height: 6px;
  width: 6px;
}

.two-projects-maint {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 21px;
  width: 90%;
}


