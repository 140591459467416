.academics {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 225px;
  position: relative;
  width: 55%;
}

.academics-1 {
  align-self: stretch;
  height: 42px;
  letter-spacing: 0;
  line-height: 41.6px;
  margin-top: -1px;
  white-space: nowrap;
  width: 90%;
}

@media (max-width: 1240px) {
  .academics {
    width: 90%;
  }
}
