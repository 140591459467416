.x02-about-me-desktop {
  align-items: flex-start;
  background-color: var(--neutralbackground-white);
  display: flex;
  flex-direction: column;
  /*height: 2413px;*/
  position: relative;
  width: 100%;
  overflow: hidden;
}

.about {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 56px;
  margin-left: 5%;
  margin-top: 10%;
  width: 100%;
  z-index: 1;
}
