.projects {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-top: 8rem;
  position: relative;
  z-index: 4;
  padding: 0 2rem 0 2rem;
}

.header-3 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 32px;
  /*min-height: 167px;*/
  /*position: relative;*/
}

.header-4 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 32px;
  min-height: 167px;
}

.title-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 108px;
}

.project-1 {
  align-self: stretch;
  height: 42px;
  letter-spacing: 0;
  line-height: 41.6px;
  margin-top: -1px;
  white-space: nowrap;
  width: 1116px;
}

.my-recent-work {
  align-self: stretch;
  height: 58px;
  letter-spacing: 0;
  line-height: 57.6px;
  white-space: nowrap;
  width: 1116px;
}

.here-are-some-recent {
  align-self: stretch;
  height: 27px;
  letter-spacing: 0;
  line-height: 27px;
  white-space: nowrap;
  width: 1116px;
}

.projects-1 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 64px;
  position: relative;
}

@media (max-width: 1240px) {
  .header-3 {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .projects-1 {
    display: block;
  }
}
