.skills {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 60%;
}

.skills-1 {
  align-self: stretch;
  height: 42px;
  letter-spacing: 0;
  line-height: 41.6px;
  margin-top: -1px;
  white-space: nowrap;
  width: 90%;
}

.details {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  /*min-height: 242px;*/
  /*min-width: 696px;*/
  width: 90%;
  position: relative;
}

.detail-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  /*min-height: 152px;*/
  /*min-width: 696px;*/
  width: 90%;
}

.programming-language {
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  white-space: nowrap;
}

.logo-13 {
  display: flex;
  gap: 38px;
  flex-wrap: wrap;
}

.logo-13 img {
  height: 38px;
}

@media (max-width: 1240px) {
  .skills {
    width: 100%;
  }
}
