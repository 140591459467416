.link-4-1 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 4px;
  min-height: 18px;
  min-width: 83px;
  transition: all 0.2s ease-in-out;
}

.link-4-1:hover {
  transform: scale(1.1);
}

.see-more-2 {
  letter-spacing: 0;
  line-height: 18.4px;
  white-space: nowrap;
}

.iconnavigationarrow_forward_24px-4 {
  height: 16px;
  width: 16px;
}

.link-4-1.link-5,
.link-4-1.link-6,
.link-4-1.link-7 {
  cursor: unset;
  transition: unset;
}

