.featured-projects {
  align-items: center;
  background-color: var(--link-water);
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 8rem 0 4rem 0;
  width: 100%;
  z-index: 1;
}

.header-7 {
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 32px;
  /*width: 1222px;*/
}

.featured-projects-1 {
  flex: 1;
  /*height: 42px;*/
  letter-spacing: 0;
  line-height: 41.6px;
  /*white-space: nowrap;*/
  /*width: 1064px;*/
}

.next-prev {
  align-items: center;
  display: flex;
  gap: 16px;
  min-height: 36px;
  min-width: 126px;
  position: relative;
}

.text-1-1 {
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.projects-10 {
  align-items: center;
  display: flex;
  gap: 6rem;
  max-width: 70rem;
}

.projects-11 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
}

.thumbnail-6 {
  height: 276px;
  max-width: 490px;
  width: 100%;
}

@media (max-width: 1240px) {
  .featured-projects {
    align-items: unset;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .projects-10 {
    gap: 4rem;
    flex-direction: column;
  }

  .projects-11 {
    align-items: center;
  }
}

@media (max-width: 520px) {
  .thumbnail-6 {

  }
}
