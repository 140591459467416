.icon-contact-4 {
  align-items: center;
  background-color: var(--primaryblue);
  border-radius: 100px;
  display: flex;
  gap: 8px;
  justify-content: center;
  min-height: 44px;
  min-width: 44px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  transition: all 0.2s ease-in-out;
}

.icon-contact-4:hover {
  transform: scale(1.1);
}

.icon {
  height: 24px;
  width: 24px;
}

.icon-contact-4.icon-contact-7-1 .icon,
.icon-contact-4.icon-contact-7-2 .icon,
.icon-contact-4.icon-contact-7 .icon {
  margin-left: -0.5px;
}

