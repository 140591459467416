.services-1 {
  align-items: center;
  display: flex;
  gap: 8.6rem;
  margin-top: 10%;
  z-index: 3;
}

.header-2 {
  align-items: flex-start;
  flex-direction: column;
  gap: 32px;
  opacity: 0;
  transform: translate(-25px, 0);
  width: 592px;
}

.header-2.animate-enter1 {
  animation: animate-enter1-frames 0.20s ease-in-out 0.00s 1 normal forwards;
  opacity: 0;
  transform: translate(-25px, 0);
}

.title-4 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.services-2 {
  height: 2.625rem;
  letter-spacing: 0;
  line-height: 2.6rem;
  white-space: nowrap;
}

.what-i-do-in-software-development-1 {
  letter-spacing: 0;
  line-height: 3.6rem;
}

.in-the-development-o {
  letter-spacing: 0;
  line-height: 1.7rem;
  margin: 2%;
}

.logo {
  display: flex;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: 24px;
  flex-wrap: wrap;
  padding: 2%;
}

.logo img {
  height: 48px;
}

.services-3 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 32px;
  /*min-height: 472px;*/
  opacity: 0;
  position: relative;
  transform: translate(25px, 0);
  /*width: 490px;*/
}

.services-3.animate-enter2 {
  animation: animate-enter2-frames 0.20s ease-in-out 0.00s 1 normal forwards;
  opacity: 0;
  transform: translate(25px, 0);
}

@keyframes animate-enter1-frames {
  from{opacity: 0;
transform: translate(-25px, 0);
}
to{opacity: 1;
transform: translate(0,0);
}
}

@keyframes animate-enter2-frames {
  from{opacity: 0;
transform: translate(25px, 0);
}
to{opacity: 1;
transform: translate(0,0);
}
}

@media (max-width: 1240px) {
  .services-1 {
    flex-direction: column;
    padding: 5%;
  }

  .header-2 {
    width: 100%;
    text-align: center;
  }

  .services-3 {
    width: 100%;
  }

}

