.projects-12 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 64px;
  margin-top: 8rem;
  z-index: 2;
  padding: 2rem;
}

.header-8 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 112px;
}

.all-project {
  align-self: stretch;
  height: 42px;
  letter-spacing: 0;
  line-height: 41.6px;
  margin-top: -1px;
  white-space: nowrap;
  width: 1222px;
}

.tab {
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  position: relative;
  justify-content: space-around;
}

.projects-13 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 64px;
  min-height: 2322.75px;
  position: relative;
}

