.hero {
  align-items: center;
  justify-content: center;
  background-image: url(/public/img/bg.svg);
  background-size: cover;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 9em;
  gap: 2rem;
  z-index: 5;
}

.photo-profile {
  display: block;
  height: 30rem;
  opacity: 0;
  transform: translate(-25px, 0);
  /*width: 386px;*/
}

.photo-profile.animate-enter8 {
  animation: animate-enter8-frames 0.20s ease-in-out 0.00s 1 normal forwards;
  display: block;
  opacity: 0;
  transform: translate(-25px, 0);
}

.header-1 {
  /*align-items: flex-start;*/
  /*flex-direction: column;*/
  /*gap: 32px;*/
  margin-left: 10%;
  margin-right: 10%;
  opacity: 0;
  transform: translate(25px, 0);
  max-width: 43.75rem;
}

.header-1.animate-enter9 {
  animation: animate-enter9-frames 0.20s ease-in-out 0.00s 1 normal forwards;
  opacity: 0;
  transform: translate(25px, 0);
}

.title-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 133px;
}

.hi-im-faisal-hotak {
  align-self: stretch;
  color: var(--primarygreen);
  font-family: var(--font-family-source_sans_pro);
  font-size: 4rem;
  font-weight: 700;
  /*height: 83px;*/
  letter-spacing: 0;
  line-height: 5rem;
  white-space: nowrap;
  /*width: 700px;*/
}

.software-developer-i {
  align-self: stretch;
  height: 2.625rem;
  letter-spacing: 0;
  line-height: 2.6rem;
  white-space: nowrap;
  width: 43.75rem;
}

.i-am-a-software-deve {
  align-self: stretch;
  height: 6.75rem;
  letter-spacing: 0;
  line-height: 1.7rem;
}

.action {
  align-items: flex-start;
  display: flex;
  gap: 16px;
  min-height: 53px;
  min-width: 364px;
  position: relative;
}

@keyframes animate-enter9-frames {
  from{opacity: 0;
transform: translate(25px, 0);
}
to{opacity: 1;
transform: translate(0,0);
}
}

@keyframes animate-enter8-frames {
    from {opacity: 0;
      transform: translate(-25px, 0);
    }
    to  {opacity: 1;
      transform: translate(0,0);
    }
}

@media (max-width: 1240px) {
  .hero {
    flex-direction: column;
  }
}

@media (max-width: 1024px) {

}

@media (max-width: 768px) {
  .header-1 {
    text-align: center;
  }

  .hi-im-faisal-hotak {
    font-size: 3rem;
  }

  .software-developer-i {
    width: 100%;
    white-space: unset;
    margin-top: 5%;
  }

  .i-am-a-software-deve {
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .action {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .hi-im-faisal-hotak {
    font-size: 2rem;
  }

  .photo-profile {
    padding: 30%;
  }

  .action {
    align-items: unset;
    width: 100%;
    flex-direction: column;
  }
}


