.tab-non-active {
  align-items: flex-start;
  background-color: var(--neutralbackground-white);
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  /*position: relative;*/
}

.tab-non-active.tab-non-active-1 {
  padding-left: 8px;
  padding-right: 8px;
}

