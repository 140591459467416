.icon-message {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: flex-end;
  min-width: 24px;
  padding: 0 2.0px;
}

.message {
  background-image: url(/public/img/message@2x.svg);
  background-size: 100% 100%;
  height: 18px;
  width: 20px;
}

.icon-message.icon-message-3 {
  justify-content: unset;
  padding: 0 1.5px;
}

