.x01-home-desktop {
  align-items: center;
  background-color: var(--neutralbackground-white);
  display: flex;
  flex-direction: column;
  /*height: 3881px;*/
  overflow: hidden;
  /*position: relative;*/
  width: 100%;
}

@media (max-width: 1240px) {
  .x01-home-desktop {
    align-items: unset;
  }
}

