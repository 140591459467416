.tab-active {
  align-items: flex-start;
  background-color: var(--neutralbackground-white);
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-right: 16px;
  padding-top: 16px;
  position: relative;
}

.tab-active.tab-active-1 {
  padding-right: 8px;
}

