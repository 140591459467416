.card-project-1 {
  align-items: flex-start;
  background-color: var(--neutralbackground-white);
  border-radius: 16px;
  box-shadow: 0 12px 32px #2b353e14;
  flex: 1;
  flex-direction: column;
  gap: 32px;
  opacity: 0;
  padding: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  transform: translate(-25px, 0);
  width: 100%;
}

.card-project-1.animate-enter3 {
  animation: animate-enter3-frames 0.20s ease-in-out 0.00s 1 normal forwards;
  opacity: 0;
  transform: translate(-25px, 0);
}

.thumbnail {
  height: 50%;
  margin-bottom: 5%;
  object-fit: cover;
  width: 100%;
  border-radius: 1rem;
}

.title-7 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 145px;
}

.mainteneo-mobile-application {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 31.2px;
  margin-top: -1px;
}

.mainteneo-for-refrig {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 24px;
}

.infoaction {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 16px;
  position: relative;
}

.label {
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex: 1;
  gap: 8px;
  position: relative;
}

.card-project-1.card-project-2,
.card-project-1.card-project-3 {
  transform: translate(25px, 0);
}

.card-project-1.card-project-2.animate-enter4 {
  animation: animate-enter4-frames 0.20s ease-in-out 0.00s 1 normal forwards;
  opacity: 0;
  transform: translate(25px, 0);
}

.card-project-1.card-project-3.animate-enter6 {
  animation: animate-enter6-frames 0.20s ease-in-out 0.00s 1 normal forwards;
  opacity: 0;
  transform: translate(25px, 0);
}

.card-project-1.card-project-3 .thumbnail,
.card-project-1.card-project-6 .thumbnail,
.card-project-1.card-project-7 .thumbnail,
.card-project-1.card-project-8 .thumbnail {
  margin-top: -0.66px;
}

.card-project-1.card-project,
.card-project-1.card-project-4,
.card-project-1.card-project-5,
.card-project-1.card-project-6,
.card-project-1.card-project-7,
.card-project-1.card-project-8 {
  display: flex;
  opacity: unset;
  transform: unset;
}

.card-project-1.card-project .thumbnail {
  height: 307.69px;
}

@keyframes animate-enter3-frames {
  from{opacity: 0;
transform: translate(-25px, 0);
}
to{opacity: 1;
transform: translate(0,0);
}
}

@keyframes animate-enter4-frames {
  from{opacity: 0;
transform: translate(25px, 0);
}
to{opacity: 1;
transform: translate(0,0);
}
}

@keyframes animate-enter6-frames {
  from{opacity: 0;
transform: translate(25px, 0);
}
to{opacity: 1;
transform: translate(0,0);
}
}

@media (max-width: 480px) {
  .infoaction {
    flex-direction: column;
  }
}

@media (max-width: 380px) {
  .label {
    flex-direction: column;
  }
}