.projects-3,
.projects-2 {
  align-items: flex-start;
  display: flex;
  gap: 32px;
  /*min-height: 532.69px;*/
  /*position: relative;*/
  width: 100%;
}

@media (max-width: 1240px) {
  .projects-2, .projects-3 {
    flex-direction: column;
  }

}
