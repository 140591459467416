.label-2-1 {
  align-items: center;
  background-color: var(--neutralbackground-white);
  border-radius: 200px;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
}

.mobile-app {
  letter-spacing: 0.48px;
  line-height: 18px;
}

.label-2-1.label-24,
.label-2-1.label-31 {
  align-items: center;
  background-color: var(--neutralbackground-white);
  border-radius: 200px;
  display: flex;
  gap: 10px;
  justify-content: center;
  min-height: 34px;
  min-width: 90px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
}

.label-2-1.label-25,
.label-2-1.label-32 {
  align-items: center;
  background-color: var(--neutralbackground-white);
  border-radius: 200px;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
}

.label-2-1.label-26,
.label-2-1.label-27,
.label-2-1.label-29,
.label-2-1.label-33 {
  align-items: center;
  background-color: var(--neutralbackground-white);
  border-radius: 200px;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
}

.label-2-1.label-28,
.label-2-1.label-30 {
  align-items: center;
  background-color: var(--neutralbackground-white);
  border-radius: 200px;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
}

@media (max-width: 520px) {
  .label-2-1 {
    width: 100%;
    text-align: center;
  }
}
