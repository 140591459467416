.tab-non-active-4 {
  align-items: flex-start;
  background-color: var(--neutralbackground-white);
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 54px;
  min-width: 132px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  position: relative;
}

.tab-non-active-4.tab-non-active-5 {
  min-width: 116px;
  padding-left: 8px;
  padding-right: 8px;
}

