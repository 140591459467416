.card-experience-5 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--neutralbackground-white);
  border-radius: 8px;
  box-shadow: 0 12px 32px #2b353e14;
  display: flex;
  flex-direction: column;
  gap: 16px;
  /*min-height: 159px;*/
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
}

.title-17 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 8px;
}

.title-18 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 4px;
}

.web-mobile-developer-2 {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 23.4px;
  width: 90%;
}

.the-maul-2 {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 1.4rem;
  width: 90%;
}

.nov-2021-6-months-5 {
  letter-spacing: 0;
  line-height: 21px;
}

.two-projects-maint-2 {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 21px;
  width: 90%;
}

