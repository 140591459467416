.profile {
  align-items: center;
  background-color: var(--neutralbackground-white);
  border-radius: 8px;
  box-shadow: 0 12px 32px #2b353e14;
  display: flex;
  flex-direction: column;
  gap: 24px;
  left: 65%;
  overflow: hidden;
  position: fixed;
  top: 152px;
  z-index: 3;
  margin-left: 1rem;
  margin-right: 1rem;
}

.avatar {
  align-items: flex-end;
  display: flex;
}

.overlap-group-1 {
  position: relative;
  width: 100%;
}

.bg {
  width: 100%;
}

.avatar-1 {
  top: -5rem;
  left: 40%;
  object-fit: cover;
  position: relative;
}

.title-12 {
  margin-top: -5rem;
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 8px;
  /*min-height: 63px;*/
  /*padding-left: 24px;*/
  /*padding-right: 24px;*/
}

.faisal-hotak {
  align-self: stretch;
  height: 31px;
  letter-spacing: 0;
  line-height: 31.2px;
  text-align: center;
  white-space: nowrap;
  width: 100%;
}

.software-developer-i-1 {
  align-self: stretch;
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;
  width: 100%;
}

.action-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 101px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  position: relative;
}

.action-2 > a > .button-primary-large,
.action-2 > a > .button-secondary-large {
  width: 100%;
}

@media (max-width: 1240px) {
  .profile {
    position: unset;
    margin-top: 10rem;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 540px) {
  .profile {
    width: 90%;
  }

  .action-2 {
    flex-wrap: wrap;
  }
}
