:root { 
  --black: #000000;
  --boulder: #77777a;
  --gray: #847f82;
  --link-water: #d6edf8;
  --neutralbackground-white: #ffffff;
  --neutralblack: #020100;
  --neutralblack-60: #676766;
  --primaryblue: #008dd5;
  --primarygreen: #184644;
  --quill-gray: #d6d4d5;
 
  --font-size-l: 18px;
  --font-size-m: 16px;
  --font-size-s: 14px;
  --font-size-xl: 20px;
  --font-size-xs: 12px;
  --font-size-xxl: 24px;
  --font-size-xxxl: 32px;
 
  --font-family-source_sans_pro: "Source Sans Pro", Helvetica;
}
.sourcesanspro-normal-gray-12px {
  color: var(--gray);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.sourcesanspro-normal-granite-gray-14px {
  color: var(--neutralblack-60);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.sourcesanspro-semi-bold-cerulean-16px {
  color: var(--primaryblue);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 600;
}

.sourcesanspro-normal-granite-gray-16px {
  color: var(--neutralblack-60);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.sourcesanspro-bold-te-papa-green-24px {
  color: var(--primarygreen);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.sourcesanspro-bold-te-papa-green-20px {
  color: var(--primarygreen);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}

.sourcesanspro-bold-granite-gray-18px {
  color: var(--neutralblack-60);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.sourcesanspro-normal-boulder-12px {
  color: var(--boulder);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.sourcesanspro-bold-te-papa-green-16px {
  color: var(--primarygreen);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.sourcesanspro-semi-bold-cerulean-14px {
  color: var(--primaryblue);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 600;
}

.sourcesanspro-normal-black-16px {
  color: var(--neutralblack);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.sourcesanspro-bold-black-18px {
  color: var(--neutralblack);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.sourcesanspro-bold-granite-gray-16px {
  color: var(--neutralblack-60);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.sourcesanspro-bold-cerulean-24px {
  color: var(--primaryblue);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.sourcesanspro-bold-cerulean-32px {
  color: var(--primaryblue);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 700;
}

.sourcesanspro-normal-white-14px {
  color: var(--neutralbackground-white);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.sourcesanspro-bold-te-papa-green-32px {
  color: var(--primarygreen);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 700;
}

.sourcesanspro-bold-cerulean-18px {
  color: var(--primaryblue);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.sourcesanspro-semi-bold-white-18px {
  color: var(--neutralbackground-white);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 600;
}

.sourcesanspro-bold-te-papa-green-18px {
  color: var(--primarygreen);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.sourcesanspro-bold-gray-18px {
  color: var(--gray);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.sourcesanspro-semi-bold-cerulean-18px {
  color: var(--primaryblue);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 600;
}

.sourcesanspro-bold-te-papa-green-48px {
  color: var(--primarygreen);
  font-family: var(--font-family-source_sans_pro);
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
}

.sourcesanspro-normal-cerulean-16px {
  color: var(--primaryblue);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.sourcesanspro-bold-black-24px {
  color: var(--neutralblack);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.sourcesanspro-normal-granite-gray-18px {
  color: var(--neutralblack-60);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.sourcesanspro-bold-black-20px {
  color: var(--neutralblack);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}

.sourcesanspro-normal-black-18px {
  color: var(--neutralblack);
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.border-1px-quill-gray {
  border: 1px solid var(--quill-gray);
}

.border-1px-primaryblue {
  border: 1px solid var(--primaryblue);
}
