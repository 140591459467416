.about-1 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 60%;
}

.about-me {
  align-self: stretch;
  height: 42px;
  letter-spacing: 0;
  line-height: 41.6px;
  margin-top: -1px;
  white-space: nowrap;
  width: 90%;
}

.i-am-a-software-deve-1 {
  letter-spacing: 0;
  line-height: 24px;
  width: 90%;
}

@media (max-width: 1240px) {
  .about-1 {
    width: 100%;
  }
}