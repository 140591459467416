.button-secondary-medium-5 {
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  min-height: 40px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
}

.secondary-4 {
  letter-spacing: 0;
  line-height: 16.1px;
  margin-top: -1px;
  white-space: nowrap;
}

.button-secondary-medium-5.button-secondary-medium-1 {
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 40px;
  min-width: 130px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
}

.button-secondary-medium-5.button-secondary-medium-2 {
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 40px;
  min-width: 146px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
}

.button-secondary-medium-5.button-secondary-medium-3 {
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 40px;
  min-width: 107px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
}

.button-secondary-medium-5.button-secondary-medium-4 {
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 40px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
}

