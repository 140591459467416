.button-primary-large-4 {
  align-items: center;
  background-color: var(--primaryblue);
  border-radius: 4px;
  flex-direction: column;
  gap: 10px;
  min-height: 53px;
  min-width: 187px;
  opacity: 0;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  transform: translate(0, 25px);
}

.button-primary-large-4.animate-enter7 {
  animation: animate-enter7-frames 0.20s ease-in-out 0.00s 1 normal forwards;
  opacity: 0;
  transform: translate(0, 25px);
}

.primary-4 {
  letter-spacing: 0.72px;
  line-height: 20.7px;
  margin-top: -1px;
  white-space: nowrap;
}

.button-primary-large-4.button-primary-large-5 {
  display: flex;
  opacity: unset;
  transform: unset;
}

@keyframes animate-enter7-frames {
  from{opacity: 0;
transform: translate(0, 25px);
}
to{opacity: 1;
transform: translate(0,0);
}
}

