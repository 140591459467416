.card-project-10 {
  align-items: flex-start;
  background-color: var(--neutralbackground-white);
  border-radius: 16px;
  box-shadow: 0 12px 32px #2b353e14;
  flex: 1;
  flex-direction: column;
  gap: 32px;
  opacity: 0;
  padding: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  transform: translate(-25px, 0);
  width: 100%;
}

.card-project-10.animate-enter5 {
  animation: animate-enter5-frames 0.20s ease-in-out 0.00s 1 normal forwards;
  opacity: 0;
  transform: translate(-25px, 0);
}

.thumbnail-2 {
  height: 50%;
  margin-bottom: 5%;
  object-fit: cover;
  width: 100%;
  border-radius: 1rem;
}

.title-10 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mainteneo-mobile-application-3 {
  align-self: stretch;
  height: 31px;
  letter-spacing: 0;
  line-height: 31.2px;
  margin-top: -1px;
}

.mainteneo-for-refrig-3 {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 24px;
}

.infoaction-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 16px;
  min-height: 34px;
  position: relative;
}

.label-35 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  gap: 8px;
  position: relative;
  width: 100%;
}

.card-project-10.card-project-9,
.card-project-10.card-project-9-1 {
  display: flex;
  opacity: unset;
  transform: unset;
}

.card-project-10.card-project-9 .thumbnail-2 {
  height: 307.69px;
  margin-top: -0.16px;
}

@keyframes animate-enter5-frames {
  from{opacity: 0;
transform: translate(-25px, 0);
}
to{opacity: 1;
transform: translate(0,0);
}
}

@media (max-width: 480px) {
  .infoaction-3 {
    flex-direction: column;
  }
}

@media (max-width: 380px) {
  .label-35 {
    flex-direction: column;
  }
}

