.project-4,
.project-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  /*min-height: 194px;*/
  /*width: 595px;*/
  width: 100%;
}

.mainteneo-mobile-application-6,
.mainteneo-mobile-application-7 {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 41.6px;
  /*margin-top: -1px;*/
  /*white-space: nowrap;*/
  /*width: 595px;*/
}

.mainteneo-for-refrig-6,
.mainteneo-for-refrig-7 {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 24px;
}

.logo-18,
.logo-21 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 16px;
}

.logo-19,
.logo-22 {
  height: 48px;
  width: 48px;
}

.logo-20,
.logo-23 {
  height: 48px;
  width: 96px;
}

@media (max-width: 1240px) {
  .mainteneo-for-refrig-6,
  .mainteneo-for-refrig-7 {
    max-width: 100%;
  }

  .logo-18, .logo-21 {
    align-self: center;
  }
}