.button-secondary-large {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 53px;
  min-width: 207px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  transition: all 0.2s ease;
}

.button-secondary-large:hover {
  transform: scale(1.05);
}

.secondary {
  letter-spacing: 0.72px;
  line-height: 20.7px;
  margin-top: -1px;
  white-space: nowrap;
}

.button-secondary-large.button-secondary-large-1,
.button-secondary-large.button-secondary-large-2 {
  cursor: unset;
  flex: 1;
  min-width: unset;
  transition: unset;
}

.button-secondary-large.button-secondary-large-3 {
  align-self: stretch;
  cursor: unset;
  min-width: unset;
  transition: unset;
}

