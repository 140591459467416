.tab-7,
.tab-8 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-height: 38px;
  min-width: 100px;
}

.label-46,
.label-47 {
  letter-spacing: 0;
  line-height: 23.4px;
  margin-top: -1px;
  white-space: nowrap;
}

.line-8,
.line-9 {
  align-self: stretch;
  height: 1px;
  margin-bottom: -0.75px;
  object-fit: contain;
}

