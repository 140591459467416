.icon-contact,
.icon-contact-1,
.icon-contact-2,
.icon-contact-3 {
  align-items: center;
  background-color: var(--primaryblue);
  border-radius: 100px;
  display: flex;
  gap: 8px;
  justify-content: center;
  min-height: 44px;
  min-width: 44px;
  padding: 10px;
  position: relative;
  transition: all 0.2s ease-in-out;
}

.icon-contact:hover,
.icon-contact-1:hover,
.icon-contact-2:hover,
.icon-contact-3:hover {
  transform: scale(1.05);
}

