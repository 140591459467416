.list-contact-4,
.list-contact-5 {
  align-items: center;
  display: flex;
  gap: 16px;
  min-height: 44px;
  position: relative;
  width: 256px;
}

