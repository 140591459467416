.card-services {
  align-items: center;
  align-self: stretch;
  background-color: var(--neutralbackground-white);
  border-radius: 8px;
  box-shadow: 0 12px 32px #2b353e14;
  cursor: pointer;
  display: flex;
  gap: 28px;
  padding: 1rem;
  transition: all 0.2s ease-in-out;
}

.card-services:hover {
  transform: scale(1.1);
}

.image-service {
  height: 104px;
  object-fit: cover;
  width: 104px;
}

.info {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
  min-height: 90px;
  position: relative;
}

.title-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.address {
  height: 23px;
  letter-spacing: 0;
  line-height: 23.4px;
  margin-top: -1px;
  white-space: nowrap;
}

.mobile-app-development {
  /*height: 31px;*/
  letter-spacing: 0;
  line-height: 1.95rem;
  text-align: left;
}

@media (max-width: 1240px) {
  .card-services {
    text-align: center;
  }

  .image-service {
    width: auto;
    height: auto;
  }

}

@media (max-width: 620px) {
  .card-services {
    text-align: center;
  }

  .image-service {
    width: 124px;
    height: 124px;
  }
}

@media (max-width: 480px) {
  .address {
    font-size: 1rem;
  }

  .mobile-app-development {
    font-size: 1.2rem;
  }
}