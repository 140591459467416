.footer {
  align-items: center;
  background-color: var(--primaryblue);
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-right: 1.0px;
  margin-top: 80px;
  padding-bottom: 32px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 32px;
  width: 359px;
  z-index: 1;
}

.logo-2 {
  height: 40px;
  width: 164px;
}

.x2022-faisal-hotak {
  align-self: stretch;
  height: 21px;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  white-space: nowrap;
  width: 327px;
}

.footer.footer-1 {
  margin-right: unset;
  margin-top: 170px;
  padding-left: 72px;
  padding-right: 72px;
  width: 100%;
  z-index: 2;
}

.footer.footer-1 .logo-2,
.footer.footer-2 .logo-2,
.footer.footer-6 .logo-2,
.footer.footer-9 .logo-2 {
  margin-top: -0.5px;
}

.footer.footer-1 .x2022-faisal-hotak,
.footer.footer-3 .x2022-faisal-hotak,
.footer.footer-4 .x2022-faisal-hotak,
.footer.footer-5 .x2022-faisal-hotak,
.footer.footer-6 .x2022-faisal-hotak {
  width: 100%;
}

.footer.footer-3 {
  margin-right: unset;
  margin-top: 128px;
  padding-left: 72px;
  padding-right: 72px;
  width: 100%;
  z-index: 2;
}

.footer.footer-4 {
  margin-right: unset;
  margin-top: 161px;
  padding-left: 72px;
  padding-right: 72px;
  width: 100%;
  z-index: 3;
}

.footer.footer-5 {
  margin-right: unset;
  margin-top: 280px;
  padding-left: 72px;
  padding-right: 72px;
  width: 100%;
}

.footer.footer-6 {
  margin-right: unset;
  margin-top: 138px;
  padding-left: 72px;
  padding-right: 72px;
  width: 100%;
  z-index: 3;
}

.footer.footer-7 {
  margin-top: 99px;
}

.footer.footer-8 {
  z-index: 4;
}

