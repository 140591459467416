.tab-non-active-2 {
  align-items: flex-start;
  background-color: var(--neutralbackground-white);
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 54px;
  min-width: 101px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  position: relative;
}

.tab-non-active-2.tab-non-active-3 {
  min-width: 85px;
  padding-left: 8px;
  padding-right: 8px;
}

