.x03-projects-desktop {
  align-items: center;
  background-color: var(--neutralbackground-white);
  /*display: flex;*/
  flex-direction: column;
  position: relative;
  width: 100%;
  overflow: hidden;
}

