.contact-5 {
  background-color: var(--link-water);
  border-radius: 16px;
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;
  transform: rotate(25deg);
  /*width: 63.375rem;*/
  height: 35rem;
  width: 75%;
  z-index: 1;
}

.contact-5.animate-enter {
  animation: animate-enter-frames 0.20s ease-in-out 0.00s 1 normal forwards;
  opacity: 0;
  transform: rotate(25deg);
}

.overlap-group {
  height: 31rem;
  position: relative;
  /*top: 4rem;*/
  top: 12%;
}

.pattern-2 {
  height: 16rem;
  bottom: 0;
  right: 0;
  position: absolute;
  width: 18.063rem;
}

.pattern-1 {
  height: 16rem;
  left: 0;
  bottom: 0;
  position: absolute;
  width: 16rem;
}

.contact-6 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 48px;
  /*left: 105px;*/
  /*overflow: hidden;*/
  /*position: absolute;*/
  /*top: 0;*/
}

.header-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  min-height: 167px;
  width: 804px;
}

.title-11 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 108px;
}

.place-1 {
  align-self: stretch;
  height: 42px;
  letter-spacing: 0;
  line-height: 41.6px;
  margin-top: -1px;
  text-align: center;
  white-space: nowrap;
  width: 804px;
}

.interested-in-working-with-me-1 {
  align-self: stretch;
  height: 58px;
  letter-spacing: 0;
  line-height: 57.6px;
  text-align: center;
  white-space: nowrap;
  width: 804px;
}

.lets-start-collabor {
  align-self: stretch;
  height: 27px;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center;
  white-space: nowrap;
  width: 804px;
}

@keyframes animate-enter-frames {
  from{opacity: 0;
transform: rotate(25deg);
}
to{opacity: 1;
transform: rotate(0);
}
}

@media (max-width: 1048px) {
  .contact-5 {
    height: 54.5rem;
  }

  .overlap-group {
    height: 48rem;
  }

  .interested-in-working-with-me-1 {
    width: 50%;
    white-space: unset;
    margin: 4% auto 4% auto;
    font-size: 5vw;
  }
}

@media (max-width: 750px) {
  .pattern-1 {
    height: 12rem;
    width: 12rem;
  }

  .pattern-2 {
    height: 12rem;
    width: 14.063rem;
  }
}

@media (max-width: 580px) {
  .pattern-1 {
    height: 10rem;
    width: 10rem;
  }

  .pattern-2 {
    height: 10rem;
    width: 12.063rem;
  }
}

@media (max-width: 480px) {
  .pattern-1 {
    height: 8rem;
    width: 8rem;
  }

  .pattern-2 {
    height: 8rem;
    width: 10.063rem;
  }
}
