.tab-1,
.tab-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-height: 38px;
  min-width: 21px;
}

.label-40,
.label-41 {
  letter-spacing: 0;
  line-height: 23.4px;
  margin-top: -1px;
  white-space: nowrap;
}

.line-2,
.line-3 {
  align-self: stretch;
  height: 1px;
  margin-bottom: -0.5px;
  object-fit: contain;
}

