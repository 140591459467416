.projects-8 {
  align-items: flex-start;
  background-color: var(--link-water);
  display: flex;
  gap: 137px;
  padding-bottom: 104px;
  padding-left: 72px;
  padding-right: 72px;
  padding-top: 144px;
  z-index: 1;
}

.projects-9 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  min-height: 266px;
  min-width: 595px;
  position: relative;
}

.action-3 {
  align-items: flex-start;
  display: flex;
  gap: 16px;
  min-height: 40px;
  min-width: 292px;
  position: relative;
}

.thumbnail-5 {
  height: 276px;
  width: 490px;
}

