.detail-projects-1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 128px;
  position: relative;
  width: 804px;
  z-index: 2;
}

