.x04-details-project-desktop {
  align-items: center;
  background-color: var(--neutralbackground-white);
  display: flex;
  flex-direction: column;
  height: 3083px;
  position: relative;
  width: 100%;
}

