.link,
.link-1 {
  align-items: center;
  display: flex;
  gap: 4px;
  min-height: 16px;
  min-width: 75px;
}

.see-more,
.see-more-1 {
  letter-spacing: 0;
  line-height: 16.1px;
  white-space: nowrap;
}

.iconnavigationarrow_forward_24px,
.iconnavigationarrow_forward_24px-1 {
  height: 16px;
  width: 16px;
}

