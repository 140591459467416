.link-2,
.link-3 {
  align-items: center;
  display: flex;
  gap: 4px;
  min-height: 18px;
  min-width: 74px;
}

.view-all,
.view-all-1 {
  letter-spacing: 0;
  line-height: 18.4px;
  white-space: nowrap;
}

.iconnavigationarrow_forward_24px-2,
.iconnavigationarrow_forward_24px-3 {
  height: 16px;
  width: 16px;
}

@media (max-width: 1240px) {
  .link-2,
  .link-3 {
    flex-direction: column;
  }
}